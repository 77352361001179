import React from "react";

export const PhoneLogo = () => {
  return (
    <svg
      fill="#000000"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox="0 0 101 101"
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M74.185,0h-47.37c-2.178,0-3.949,1.772-3.949,3.95v93.101c0,2.178,1.771,3.949,3.949,3.949h47.37
		c2.178,0,3.948-1.771,3.948-3.949V3.95C78.134,1.772,76.363,0,74.185,0z M46.627,92.5c0-2.14,1.732-3.875,3.873-3.875
		c2.144,0,3.873,1.735,3.873,3.875c0,2.141-1.729,3.875-3.873,3.875C48.359,96.375,46.627,94.641,46.627,92.5z M25.867,85.859
		V12.281h49.268v73.578H25.867z"
        />
      </g>
    </svg>
  );
};
