import React, { createContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { request } from "../api/api";

export const UserContext = createContext(null);

export const UserProvider = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);


  const UserData = async () => {
    try {
      const response = await request.get("user/me");
      if (response.status === 200) {
        setLoading(false)
        setData(response.data.data);
      }
    } catch (error) {
      console.error("Authentication check failed:", error);
    }
  };
  useEffect(() => {
    UserData();
  }, []);

  return (
    <UserContext.Provider value={{ data, UserData, loading }}>
      <Outlet />
    </UserContext.Provider>
  );
};
