import {
  LogoutOutlined,
  MoreOutlined,
  SafetyOutlined,
  SaveOutlined,
  SettingOutlined,
  StarOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Breadcrumb,
  Dropdown,
  Menu,
  Modal,
  Typography,
  message,
} from "antd";
import React, { useContext, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation, useParams } from "react-router-dom";
import { Logo } from "../../assets/logo";
import { request } from "../api/api";
import { deleteCookie } from "../api/cookies";
import { AuthContext } from "../context/auth-content";
import { UserContext } from "../context/user-context";
import { Assessment } from "./components/assessment/assessment";
import { EditProfileNav } from "./components/edit-profile-nav/edit-profile-nav";
import { ImageUpload } from "./components/image-upload/profile-image-upload";
import { ProfileInfo } from "./components/profile-info/profile-info";
import ResponseMediaQuery from "./components/response";
import "./style/navbar.css";

const Navbar = () => {
  const data = useContext(UserContext);
  const { checkAuth } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const matches = useMediaQuery({ query: "(max-width: 1023px)" });
  const { pathname } = useLocation();
  const { id } = useParams();

  const handleSavedFavority = () => {
    if (id) {
      request.post(`favorite/${id}`).then((res) => {
        message.success("Favorite qo'shildi");
      });
    }
  };

  const items = [
    {
      label: (
        <span className="text-[15px]" onClick={handleSavedFavority}>
          <SaveOutlined className="pr-1" />
          Favorite qo'shish
        </span>
      ),
      key: "0",
    },
    {
      label: (
        <span className="text-[15px]">
          <StarOutlined className="pr-1" />
          Kursni baholash
        </span>
      ),
      key: "1",
    },
    {
      label: (
        <span className="text-[15px]">
          <SafetyOutlined className="pr-1" />
          Taklif va shikoyatlar
        </span>
      ),
      key: "2",
    },
    {
      type: "divider",
    },
  ];

  const handleClose = () => {
    setOpen(false);
    setEditProfile(false);
  };
  const handleMenuClick = (e) => {
    if (e.key === "settings") {
      setOpen(true);
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item
        style={{ padding: "10px 20px" }}
        key="settings"
        icon={<SettingOutlined />}
      >
        Settings
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <nav
        className={
          pathname?.split("/")[2] !== "watch"
            ? "bg fixed z-40 py-3 w-full pr-3 ps-3  border-b z-40"
            : "bg w-full bg-gray-100 dark:bg-gray-900 py-1 fixed top-0 left-0 lg:pl-80 right-0 flex items-center justify-between px-2  z-40"
        }
      >
        <div className=" mx-auto flex w-full items-center justify-between">
          <div className="flex items-center space-x-2">
            {matches && pathname?.split("/")[2] !== "watch" && (
              <ResponseMediaQuery />
            )}
            <Link className="flex items-center gap-1 pb-1" to="/">
              <Logo />
              <p
                style={{ letterSpacing: "3px" }}
                className="text-[23px] font-bold font-space-grotesk"
              >
                Premium
              </p>
            </Link>
          </div>

          <div className="flex items-center space-x-5">
            {pathname?.split("/")[2] === "watch" && <Assessment />}

            <Dropdown
              className="cursor-pointer"
              overlay={menu}
              trigger={["click"]}
            >
              <Avatar
                size={43}
                src={data?.data?.picture && data?.data?.picture}
                icon={<UserOutlined />}
              />
            </Dropdown>
            {pathname?.split("/")[2] === "watch" && (
              <>
                <Dropdown
                  placement="bottomRight"
                  menu={{
                    items,
                  }}
                  trigger={["click"]}
                >
                  <span>
                    <MoreOutlined
                      onClick={(e) => e.preventDefault()}
                      className="cursor-pointer opacity-60 transition-all duration-200 hover:opacity-100"
                      style={{ fontSize: 25 }}
                    />
                  </span>
                </Dropdown>
              </>
            )}
          </div>
        </div>
      </nav>
      <Modal
        mask={true}
        keyboard={true}
        style={{ fontSize: "25px" }}
        title={!editProfile ? "Sozlamalar" : ""}
        visible={open}
        onOk={() => setOpen(false)}
        onCancel={handleClose}
        footer={false}
        width={"700px"}
      >
        {!editProfile ? (
          <>
            <ProfileInfo setEditProfile={setEditProfile} />
          </>
        ) : (
          <>
            <Breadcrumb
              className="mb-4"
              items={[
                {
                  title: (
                    <>
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          setEditProfile(false);
                        }}
                      >
                        <SettingOutlined /> <span>Sozlamalar</span>
                      </div>
                    </>
                  ),
                },
                {
                  title: "Profilni yangilash",
                },
              ]}
            />
            <div
              className="flex items-center justify-between pt-2 pb-3 mt-1 mb-1"
              onClick={() => setEditProfile(true)}
            >
              <div className="flex cursor-pointer items-center gap-3">
                <div>
                  <Avatar
                    size={{ sm: 50, md: 65, lg: 65, xl: 65, xxl: 65 }}
                    src={data?.data?.picture}
                    icon={<UserOutlined />}
                  />
                </div>
                <span>
                  <Typography className="text-[large] font-medium">
                    Tohirbek Uktamov
                  </Typography>
                  <ImageUpload />
                </span>
              </div>
            </div>
            {<EditProfileNav handleClose={handleClose} />}
          </>
        )}
      </Modal>
    </>
  );
};

export default Navbar;
