import {
  DesktopOutlined,
  DownOutlined,
  OrderedListOutlined,
  RightOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { Collapse } from "antd";
import React, { useContext } from "react";
import { CourseContext } from "../../../../context/course-context";
const { Panel } = Collapse;

export const CourseContent = () => {
  const item = useContext(CourseContext);

  const items = item.courseSection?.map((u) => ({
    key: u.id, // unikal key uchun u.id ni ishlatamiz

    header: (
      <span className="text-white text-[15px]  custom-text-nowrap">
        {u?.title}
      </span>
    ),
    content: (
      <>
        {item.courseItems?.map(
          (e, index) =>
            u.id === e.section_id && (
              <p key={index} className="mt-3  custom-text-nowrap">
                <VideoCameraOutlined
                  style={{ fontSize: 20, paddingRight: 15 }}
                />
                {e.title}
              </p>
            )
        )}
      </>
    ),
  }));

  return (
    <div className="w-full md:p-8 p-4 mb-2 custom-content-bg">
      <p className="mb-7 leading-7 text-2xl max-md:text-2xl font-semibold">
        Kurs haqida
      </p>
      <div className="flex gap-[70px]">
        <div className="flex flex-col gap-1 ">
          <OrderedListOutlined style={{ fontSize: 40, color: "white" }} />
          <p className="text-white"> Bo'limlar soni</p>
          <span className="text-2xl font-semibold text-white">
            {item.courseSection?.length + " ta"}
          </span>
        </div>
        <div className="flex flex-col gap-1 ">
          <DesktopOutlined style={{ fontSize: 40, color: "white" }} />
          <p className="text-white">Darsliklar soni</p>
          <span className="text-2xl font-semibold text-white">
            {item.courseItems?.length + " ta"}
          </span>
        </div>
      </div>
      <Collapse
        expandIcon={({ isActive }) =>
          !isActive ? (
            <RightOutlined style={{ color: "white" }} />
          ) : (
            <DownOutlined style={{ color: "white" }} />
          )
        }
        ghost
        className="mt-8"
      >
        {items?.map((item) => (
          <Panel
            key={item.key}
            header={item.header}
            style={{ backgroundColor: "#3d3c582b", padding: "0 !important" }}
          >
            <div className="px-2 text-white">{item.content}</div>
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};
