import React, { createContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { request } from "../api/api";

export const UserCommentContext = createContext(null);

export const UserCommentProvider = () => {
  const [comments, setComments] = useState([]);

  const UserComments = async () => {
    try {
      await request.get("course-comments").then((res) => {
        setComments([...res.data]);
      });
    } catch (error) {}
  };

  useEffect(() => {
    UserComments();
  }, []);

  return (
    <UserCommentContext.Provider value={{ comments, UserComments }}>
      <Outlet />
    </UserCommentContext.Provider>
  );
};
