import React, { createContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { request } from "../api/api";

export const LessonContext = createContext(null);

export const LessonViewProvider = () => {
  const [viewLesson, setViewLesson] = useState(null);
  const [completionPercentage, setCompletionPercentage] = useState(0);

  const LessonViewData = async () => {
    try {
      const lessonResponse = await request.get("video-complated");
      setViewLesson(lessonResponse.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setViewLesson(false);
      }
    }

    try {
      const percentageResponse = await request.get(
        "video-complated/percentage"
      );
      setCompletionPercentage(percentageResponse.data.completionPercentage);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    LessonViewData();
  }, []);

  return (
    <LessonContext.Provider
      value={{ viewLesson, completionPercentage, LessonViewData }}
    >
      <Outlet />
    </LessonContext.Provider>
  );
};
