import { LockOutlined } from "@ant-design/icons";
import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../context/auth-content";

export const Sidebar = ({ onClose }) => {
  const path = useLocation().pathname;
  const { userRole } = useContext(AuthContext);
  const handleCloseResponseModel = () => {
    if (onClose) {
      onClose();
    }
  };
  return (
    <>
      <div className="flex flex-col gap-2">
        <h3 className="font-medium border-b pb-2">Sahifalar</h3>
        <div
          data-orientation="horizontal"
          role="none"
          className="shrink-0 bg-border h-[1px] w-full"
        ></div>

        <Link onClick={handleCloseResponseModel} to="/">
          <button
            className={`whitespace-nowrap text-sm font-medium  h-9 px-3 text-left w-full ${
              path === "/" &&
              "custom-viollet-bg transition duration-700 ease-in-out"
            }`}
          >
            Bosh sahifa
          </button>
        </Link>
        <Link onClick={handleCloseResponseModel} to="/course">
          <button
            className={`whitespace-nowrap text-sm font-medium  h-9 px-3 text-left w-full ${
              path === "/course" &&
              "custom-viollet-bg transition duration-700 ease-in-out"
            }`}
          >
            Darsliklar
          </button>
        </Link>
        <Link onClick={handleCloseResponseModel} to="/dashboard">
          <button
            className={`whitespace-nowrap text-sm font-medium  h-9 px-3 text-left w-full ${
              path.split("/")[1] === "dashboard" &&
              "custom-viollet-bg transition duration-700 ease-in-out"
            }`}
          >
            Shaxsiy kabinet
          </button>
        </Link>
        {userRole === "admin" && (
          <Link onClick={handleCloseResponseModel} to="/users">
            <button
              className={`whitespace-nowrap text-sm font-medium  h-9 px-3 text-left w-full ${
                path.split("/")[1] === "users" &&
                "custom-viollet-bg transition duration-700 ease-in-out"
              }`}
            >
              Barcha foydalanuvchilar
            </button>
          </Link>
        )}
      </div>
      <div className="flex flex-col gap-2 mt-8">
        <h3 className="font-medium border-b pb-2">Foydali manbalar</h3>
        <button
          className={`whitespace-nowrap text-sm font-medium  h-9 px-3 text-left w-full ${
            path === "/books" &&
            "custom-viollet-bg transition duration-700 ease-in-out"
          }`}
        >
          <div className="flex items-center justify-between">
            <span>Strategy PDF</span>
            <LockOutlined />
          </div>
        </button>
      </div>
    </>
  );
};
