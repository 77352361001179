import React from "react";

export function Logo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={50}
      height={50}
      viewBox="0 0 700 700"
    >
      <path
        fill="#e2e7e4"
        fillRule="evenodd"
        d="M137.444 158c0 16.775.137 23.637.304 15.25.167-8.387.167-22.113 0-30.5s-.304-1.525-.304 15.25m255.998 1c0 16.225.138 22.862.306 14.75.168-8.113.168-21.388 0-29.5-.168-8.113-.306-1.475-.306 14.75m63.037 62.5c0 50.6.122 71.447.271 46.326.148-25.121.148-66.521 0-92-.149-25.479-.271-4.926-.271 45.674m-123.224 191l-.237 195 15.491 13.27c8.52 7.298 15.491 13.053 15.491 12.788 0-.265-6.637-6.2-14.75-13.189l-14.75-12.707-.504-195.081-.504-195.081-.237 195M393.461 267c0 25.575.13 36.038.288 23.25.158-12.787.158-33.712 0-46.5-.158-12.787-.288-2.325-.288 23.25m168.773 75.731L562 372.962l-22.25.289-22.25.289 22.281.23 22.282.23.484-1.262c.266-.693.357-14.531.203-30.75l-.281-29.488-.235 30.231M393.465 425c0 28.875.128 40.688.284 26.25.156-14.438.156-38.063 0-52.5-.156-14.437-.284-2.625-.284 26.25m16.285-51.252c8.387.167 22.113.167 30.5 0s1.525-.304-15.25-.304-23.637.137-15.25.304m46.729 90.752c0 50.6.122 71.447.271 46.326.148-25.121.148-66.521 0-92-.149-25.479-.271-4.926-.271 45.674m-63.06 32.5c0 10.725.147 15.112.327 9.75.179-5.363.179-14.137 0-19.5-.18-5.363-.327-.975-.327 9.75M510.5 511.598l-6 5.595 6.25-5.195c5.488-4.563 6.93-6.003 6-5.996-.138.001-2.95 2.519-6.25 5.596M220 510.257c0 .244 2.813 2.864 6.25 5.824 3.438 2.959 5.621 4.588 4.852 3.619-1.616-2.036-11.102-10.105-11.102-9.443m19 16.596c3.575 3.344 6.928 6.095 7.45 6.114 1.085.038 1.449.374-7.45-6.889l-6.5-5.306 6.5 6.081m250.5 2.741l-7 6.577 7.25-6.231c6.438-5.533 7.881-6.962 7-6.931-.137.005-3.4 2.968-7.25 6.585m-230.062 14.64c3.334 3.153 6.415 5.741 6.847 5.75.717.015-.85-1.405-9.847-8.925-1.684-1.407-.334.021 3 3.175m202.761 8.317c-3.465 2.997-5.94 5.438-5.5 5.425.441-.014 3.501-2.471 6.801-5.461 7.774-7.045 6.857-7.019-1.301.036M379.5 623.583l-6 5.58 6.25-5.232c5.558-4.651 6.857-5.935 6-5.929-.137.001-2.95 2.512-6.25 5.581"
      />
      <path
        fill="#fbfbfb"
        fillRule="evenodd"
        d="M138 158v30h68v155.5c0 85.525.342 155.5.76 155.5s1.881 1.033 3.25 2.295c2.428 2.239 6.717 5.879 26.061 22.123 5.186 4.355 9.879 8.405 10.429 9 1.939 2.098 19.341 16.582 19.922 16.582.324 0 .474-81.225.333-180.5L266.5 188h126.442l.279-29.584c.153-16.272.092-29.772-.138-30-.229-.229-57.716-.416-127.75-.416H138v30m318.765 62.5l.266 92.5h-64.068l.037-46.25c.021-25.437.03-46.812.019-47.5L393 218h-59l.043 194.75.043 194.75 15.47 13.25c8.509 7.287 16.027 13.233 16.707 13.212.681-.02 3.037-1.622 5.237-3.559s7.825-6.738 12.5-10.669 8.845-7.617 9.266-8.19c.422-.574.464-54.481.095-119.794L392.689 373h64.332l-.261 92c-.143 50.6-.101 92 .094 92 .194 0 4.694-3.707 10-8.238 5.305-4.53 10.748-9.143 12.096-10.25 1.347-1.106 4.497-3.831 7-6.054 2.502-2.224 10.512-9.146 17.8-15.383l13.25-11.34V373h45v-60.073l-22.501.286-22.501.287.001-92.75L517 128h-60.5l.265 92.5"
      />
    </svg>
  );
}
