import { SaveOutlined } from "@ant-design/icons";
import { Button, Card, Result, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { FoundFavorite } from "../assets/found";
import { Link } from "react-router-dom";
import Meta from "antd/es/card/Meta";
import { request } from "../../../api/api";

export const Saved = () => {
  const [favorite, setFavorite] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    request
      .get("favorite")
      .then((res) => {
        setLoading(false);
        setFavorite(res.data);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="mt-9">
      <h2 className="text-xl font-medium">
        <SaveOutlined className="pr-1" />
        Favorite
      </h2>
      <div className="mt-8 flex gap-12 flex-wrap">
        {loading ? (
          <Spin className="!m-[auto]" size="large"></Spin>
        ) : favorite.length === 0 ? (
          <Result
            className="!m-[auto]"
            icon={<FoundFavorite />}
            title={
              <>
                <p className="text-[#fff] text-lg">
                  Sizda favorite mavjud emas
                </p>
              </>
            }
            extra={
              <Link to="/course">
                <Button type="primary">Darsliklarga o'tish</Button>
              </Link>
            }
          />
        ) : (
          favorite?.map((e, index) => (
            <Link key={index} to={`/course/watch/${e?.video?._id}`}>
              <Card
                hoverable
                style={{
                  maxWidth: 270,
                  border: "none",
                  overflow: "hidden",
                }}
                cover={
                  <img
                    alt="Tradehouse"
                    src={`https://video.gumlet.io/${e?.video.collection_id}/${e?.video.video}/thumbnail-1-0.png`}
                  />
                }
              >
                <Meta
                  title={
                    <span className="custom-text-nowrap">{e?.video.title}</span>
                  }
                  description={
                    <span className="custom-text-nowrap">
                      Bo'lim : {e?.video?.section_id.title}
                    </span>
                  }
                />
              </Card>
            </Link>
          ))
        )}
      </div>
    </div>
  );
};
