import { Spin } from "antd";
import React, { createContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { request } from "../api/api";

export const AuthContext = createContext(null);

export const AuthProvider = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userID, setUserId] = useState();
  const [userRole, setUserRole] = useState();
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  const checkAuth = async () => {
    try {
      const response = await request.get("/check");
      console.log(response.data.data.role);
      if (response.status === 200) {
        if (response.data.data.role === "user") {
          return (window.location.href = "https://tradehouse.uz/premium");
        }
        setIsAdmin(response.data.data.role === "admin");
        setUserId(response.data.data.user);
        setUserRole(response.data.data.role);
        setIsAuthenticated(true);
        setLoading(false);
      } else {
        setIsAuthenticated(false);
      }
    } catch (error) {
      setIsAuthenticated(false);
      if (error?.response?.status === 401) {
        return (window.location.href = "https://tradehouse.uz/auth");
      }
      if (error?.response?.status === 403) {
        return (window.location.href = "https://tradehouse.uz/blocked");
      }
    }
  };
  useEffect(() => {
    checkAuth();
  }, [navigate]);

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, loading, userID, userRole, isAdmin, checkAuth }}
    >
      {loading ? (
        <div className="w-screen h-screen flex items-center justify-center">
          <Spin className="!m-[auto]" size="large"></Spin>
        </div>
      ) : (
        <Outlet />
      )}
    </AuthContext.Provider>
  );
};
