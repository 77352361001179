import {
  CrownOutlined,
  InfoOutlined,
  SettingOutlined,
  StopOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Dropdown,
  Input,
  Menu,
  Modal,
  Select,
  Space,
  Spin,
  Typography,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { request } from "../../api/api";
import { AuthContext } from "../../context/auth-content";
import { Latop } from "../../navbar/assets/latop";
import { PhoneLogo } from "../../navbar/assets/phone";

export const AllUser = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [allUser, setAllUser] = useState([]);
  const [allUserCount, setAllUserCount] = useState(0);
  const [oneData, setOneData] = useState({});
  const [modalLoading, setModalLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterRole, setFilterRole] = useState("");
  const [usersToLoad, setUsersToLoad] = useState(15);

  const { userID } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await request.get(
          `user/find?email=${searchTerm}&role=${filterRole}&limit=${usersToLoad}`
        );
        setAllUser([...res.data]);
        setLoadingButton(false);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [searchTerm, filterRole, usersToLoad]);
  useEffect(() => {
    request.get(`user/find`).then((r) => {
      setAllUserCount(r.data?.length);
    });
  }, []);

  const showLoading = (id) => {
    setOpen(true);
    setModalLoading(true);
    request
      .get(`user/find/${id}`)
      .then((res) => {
        setOneData(res.data);
        setModalLoading(false);
      })
      .catch((err) => {
        message.error("Xatolik");
        setModalLoading(false);
      });
  };

  const handleChange = (filter) => {
    if (filter === "Oddiy foydalanuvchilar") {
      setFilterRole("user");
    } else if (filter === "Barcha foydalanuvchilar") {
      setFilterRole("");
    }
    if (filter === "Premium foydalanuvchilar") {
      setFilterRole("premium-user");
    }
  };

  const handlePremiumAdd = (id) => {
    setModalLoading(true);
    request
      .patch(`user/premium/add/${id}`)
      .then((res) => {
        setAllUser(res.data);
        setModalLoading(false);
        message.success("Foydalanuvchi premiumga qo'shildi");
      })
      .catch((err) => {
        console.log(err);
        setModalLoading(false);
      });
  };

  const handlePremiumRemover = (id) => {
    request
      .delete(`user/premium/remove/${id}`)
      .then((res) => {
        setAllUser(res.data);
        message.error("Premium bekor qilindi");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleBlocked = (id) => {
    request
      .patch(`user/blocked/add/${id}`)
      .then((res) => {
        setAllUser(res.data);
        message.warning("Foydalanuvchi bloklandi");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleBlockedRemove = (id) => {
    request
      .patch(`user/blocked/remove/${id}`)
      .then((res) => {
        setAllUser(res.data);
        message.success("Muvaffaqiyatli");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadMoreUsers = () => {
    setLoadingButton(true);
    setUsersToLoad(usersToLoad + 15);
  };

  return (
    <>
      <div className="flex gap-4 items-center justify-between">
        <Input
          className="custom_placeholder !bg-[#414c5d73] text-white max-w-[300px] px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue"
          placeholder="Izlash...."
          style={{ outline: "none", borderColor: "transparent" }}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div>
          <Space wrap>
            <Select
              defaultValue="Barcha foydalanuvchilar"
              style={{
                maxWidth: 220,
              }}
              className="custom-selection"
              onChange={handleChange}
              options={[
                {
                  filter: "",
                  value: "Barcha foydalanuvchilar",
                  label: "Barcha foydalanuvchilar",
                },
                {
                  filter: "premium-user",
                  value: "Premium foydalanuvchilar",
                  label: "Premium foydalanuvchilar",
                },
                {
                  filter: "user",
                  value: "Oddiy foydalanuvchilar",
                  label: "Oddiy foydalanuvchilar",
                },
              ]}
            />
          </Space>
        </div>
      </div>
      <p className="mt-12">
        Barcha foydalanuvchilar soni : &nbsp; {allUserCount}
      </p>
      <div
        className={`${
          loading && "justify-center"
        } mt-12 flex gap-12 max-lg:justify-center flex-wrap`}
      >
        {loading ? (
          <Spin className="!m-[auto] pt-12" size="large" />
        ) : (
          <>
            {allUser?.map((e) => (
              <div key={e.id} className="max-w-[300px]">
                <div className="flex gap-2">
                  <div className="relative">
                    <Avatar
                      size={55}
                      src={e?.picture}
                      icon={<UserOutlined />}
                    />
                    <span className="absolute bottom-0 left-0">
                      {e?.blocked && (
                        <StopOutlined
                          style={{
                            color: "red",
                          }}
                        />
                      )}
                      {!e?.blocked && e.role === "premium-user" && (
                        <CrownOutlined style={{ color: "gold" }} />
                      )}
                    </span>
                  </div>
                  <span>
                    <h3
                      className="max-w-[165px] custom-text-nowrap cursor-[default]"
                      title={`${e?.first_name && e.first_name} ${
                        e?.last_name && e.last_name
                      }`}
                    >
                      {e?.first_name && e.first_name + " "}
                      {e?.last_name && " " + e.last_name}
                    </h3>
                    <p
                      className="text-sm max-w-[165px] text-[#a9a9a9] mt-1 custom-text-nowrap cursor-[default]"
                      title={e.email}
                    >
                      {e?.email}
                    </p>
                  </span>
                  <Dropdown
                    overlay={
                      <Menu>
                        {e.id !== userID && e?.role === "user" && (
                          <span onClick={() => handlePremiumAdd(e.id)}>
                            <Menu.Item key="1" icon={<CrownOutlined />}>
                              Premium a'zo qilish
                            </Menu.Item>
                          </span>
                        )}
                        {e.id !== userID && e?.role === "premium-user" && (
                          <span onClick={() => handlePremiumRemover(e.id)}>
                            <Menu.Item key="2" icon={<CrownOutlined />}>
                              Premium bekor qilish
                            </Menu.Item>
                          </span>
                        )}
                        <Link to={`/users/${e?.id}`}>
                          <Menu.Item
                            key="4"
                            onClick={() => showLoading(e?.id)}
                            icon={<InfoOutlined />}
                          >
                            Malumot
                          </Menu.Item>
                        </Link>
                        {e.id !== userID && (
                          <>
                            {!e?.blocked ? (
                              <span onClick={() => handleBlocked(e?.id)}>
                                <Menu.Item
                                  key="3"
                                  icon={
                                    <StopOutlined
                                      style={{
                                        color: "red",
                                      }}
                                    />
                                  }
                                >
                                  Bloklash
                                </Menu.Item>
                              </span>
                            ) : (
                              <span onClick={() => handleBlockedRemove(e?.id)}>
                                <Menu.Item
                                  key="3"
                                  icon={
                                    <StopOutlined
                                      style={{
                                        color: "red",
                                      }}
                                    />
                                  }
                                >
                                  Blokdan ochish
                                </Menu.Item>
                              </span>
                            )}
                          </>
                        )}
                      </Menu>
                    }
                    trigger={["click"]}
                  >
                    <SettingOutlined className="mt-3 text-lg" />
                  </Dropdown>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      {allUser.length < allUserCount && allUser.length >= 8 && (
        <Button
          loading={loadingButton}
          onClick={loadMoreUsers}
          className="load-more-button mt-8"
        >
          Ko'proq yuklash
        </Button>
      )}
      <Modal
        title={<p>Foydalanuvchi haqida malumot</p>}
        footer={false}
        loading={loading}
        centered
        open={open}
        onCancel={() => setOpen(false)}
      >
        {modalLoading ? (
          <div className="text-center">
            <Spin className="!m-[auto] pt-4" size="large"></Spin>
          </div>
        ) : oneData ? (
          <>
            <div className="mt-9 text-center">
              <Avatar
                size={80}
                src={oneData?.user?.picture}
                icon={<UserOutlined />}
              />
              <h3 className="max-w-[80%] mt-4 font-medium text-lg m-auto custom-text-nowrap cursor-[default]">
                {oneData?.user?.first_name && oneData?.user?.first_name}
                {" " + oneData?.user?.last_name && oneData?.user?.last_name}
              </h3>
            </div>
            <div className="mt-6 flex flex-col gap-2">
              <p className="text-[15px]">
                Viloyat nomi: {oneData?.user?.region}
              </p>
              <p className="text-[15px]">Shahar nomi: {oneData?.user?.city}</p>
              <h2 className="text-[15px] mt-2 font-medium text-center">
                Status
              </h2>
              <p className="text-[15px]">
                Foydalanuvchi :
                {oneData?.user?.role === "user"
                  ? " Oddiy foydalanuvchi"
                  : " Premium a'zosi"}
              </p>
              <p className="text-[15px]">
                Holat : {oneData?.user?.blocked ? "Bloklangan" : "Bloklanmagan"}
              </p>
              {oneData?.active?.length !== 0 && (
                <>
                  <h2 className="text-[15px] mt-2 font-medium text-center">
                    Ulangan qurilmalar
                  </h2>
                  <div className=" mt-5" style={{ borderColor: "#ededed" }}>
                    <div className="mt-1 p-1 flex flex-col gap-3">
                      {modalLoading ? (
                        <Spin className="!m-[auto] pt-4" size="large"></Spin>
                      ) : (
                        oneData?.active?.map((e) =>
                          e.isMobile ? (
                            <div
                              key={e.id}
                              className="flex gap-5 items-center cursor-default"
                            >
                              <span style={{ width: "70px", height: "70px" }}>
                                <PhoneLogo />
                              </span>
                              <span>
                                <Typography className="font-semibold">
                                  {e.country && e.country}/{" "}
                                  {e.region && e.region}
                                </Typography>
                                <Typography className="custom-text-nowrap max-w-[250px] ">
                                  IP :{e.ip && e.ip}
                                </Typography>
                              </span>
                            </div>
                          ) : (
                            <div
                              key={e.id}
                              className="flex gap-5 items-center cursor-default"
                            >
                              <span style={{ width: "70px", height: "70px" }}>
                                <Latop />
                              </span>
                              <span>
                                <Typography className="font-semibold">
                                  {e.country && e.country}/{" "}
                                  {e.region && e.region}
                                </Typography>
                                <Typography className="custom-text-nowrap max-w-[250px] ">
                                  IP :{e.ip && e.ip}
                                </Typography>
                              </span>
                            </div>
                          )
                        )
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          "Malumot topilmadi"
        )}
      </Modal>
    </>
  );
};
