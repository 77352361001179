import React from "react";

export const DotSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      strokeLinejoin="round"
      strokeLinecap="round"
      fill="inherit"
    >
      <circle cx="12.1" cy="12.1" r={1} />
    </svg>
  );
};
