import { LeftOutlined } from "@ant-design/icons";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Form, Input, Button, message } from "antd";
import { UserContext } from "../../../../context/user-context";
import "./edit-profile.css";
import { request } from "../../../../api/api";

export const EditProfile = () => {
  const { data, UserData } = useContext(UserContext);
  const [loadingButton, setLoadingButton] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    setLoadingButton(true);
    request
      .patch("user/update/me", { ...values })
      .then(() => {
        setLoadingButton(false);
        UserData();
        message.success("Profilingiz yangilandi.");
      })
      .catch((err) => {
        setLoadingButton(false);
        console.log(err);
        message.error("Qaytadan urinib ko'ring");
      });
  };

  if (!data) {
    return <div>Yuklanmoqda...</div>;
  }

  return (
    <>
      <div className="w-full mx-auto py-8 px-[10px] sm:p-8 bg-gray-800 rounded-md shadow-md form-container">
        <Link to="/dashboard">
          <h2 className="text-2xl font-semibold text-white mb-6 flex items-center">
            <LeftOutlined className="border p-1 mr-2 border-slate-700 text-lg  hover:border-slate-500 cursor-pointer" />
            Profilni tahrirlash
          </h2>
        </Link>
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            firstName: data?.first_name,
            lastName: data?.last_name,
            region: data?.region,
            city: data?.city,
          }}
          onFinish={handleSubmit}
        >
          <Form.Item
            name="firstName"
            label={<span className="!text-white">Ism</span>}
            rules={[
              { required: true, message: "Iltimos, ismingizni kiriting!" },
            ]}
          >
            <Input
              className="custom_placeholder !bg-[#414c5d73] mt-3 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue"
              placeholder="Ismingiz kiriting...."
              style={{ outline: "none", borderColor: "transparent" }}
            />
          </Form.Item>
          <Form.Item
            label={<span className="!text-white">Familiya</span>}
            name="lastName"
            rules={[
              { required: true, message: "Iltimos, familiyangizni kiriting!" },
            ]}
          >
            <Input
              className="custom_placeholder !bg-[#414c5d73] mt-3 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue"
              placeholder="Familiyangiz kiriting...."
              style={{ outline: "none", borderColor: "transparent" }}
            />
          </Form.Item>
          <Form.Item
            name="region"
            label={<span className="!text-white">Qaysi viloyatdansiz</span>}
            rules={[{ required: true, message: "Viloyatingizni kiriting!" }]}
          >
            <Input
              className="custom_placeholder !bg-[#414c5d73] mt-3 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue"
              placeholder="Viloyatingizni kiriting...."
              style={{ outline: "none", borderColor: "transparent" }}
            />
          </Form.Item>
          <Form.Item
            name="city"
            label={<span className="!text-white">Qaysi viloyatdansiz</span>}
            rules={[{ required: true, message: "Shahar yoki tuman manzil" }]}
          >
            <Input
              className="custom_placeholder !bg-[#414c5d73] mt-3 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue"
              placeholder="Shahar yoki tuman manzilingizni kiriting..."
              style={{
                outline: "none",
                borderColor: "transparent",
                color: "#fff",
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              loading={loadingButton}
              type="primary"
              htmlType="submit"
              className="bg-blue-500 mt-3 text-white px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue"
            >
              Saqlash
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
