import { Button, message, Spin, Tabs } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import { request } from "../../api/api";
import { LessonContext } from "../../context/lesson-context";
import { SidebarCourse } from "./components/sidebar/sidebar-video";
import { VideoComments } from "./components/video-comments/video-comments";

export const CourseVideo = () => {
  const [isComplate, setIsComplate] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingBtt, setLoadingBtt] = useState(false);
  const { id } = useParams();
  const [videoData, setVideoData] = useState();
  const matches = useMediaQuery({ query: "(max-width: 1023px)" });
  const { LessonViewData } = useContext(LessonContext);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    setLoadingBtt(false);
    request
      .get(`premium/course/${id}`)
      .then((res) => {
        setVideoData(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id, navigate]);

  useEffect(() => {
    request.get(`video-complated/one/${id}`).then((res) => {
      setIsComplate(res.data.isComplate);
      setLoading(false);
    });
  }, [id]);

  const onChange = (key) => {};

  const handleVideoComplate = () => {
    setLoadingBtt(true);
    request
      .post(`video-complated/${id}`)
      .then((res) => {
        setIsComplate(true);
        LessonViewData();
      })
      .then((rs) => {
        setLoadingBtt(false);
        message.success("Darslik yakunlandi");
      })
      .catch(() => {
        message.error("Xatolik qaytadan urinib ko'ring");
        setLoadingBtt(false);
      });
  };

  const items = [
    {
      key: "1",
      label: <span className="">Malumot</span>,
      children: (
        <>
          <div className="w-full md:p-8 p-5 mb-2 custom-content-bg flex flex-col gap-6 md:flex-row md:items-center md:justify-between">
            <span className="text-xl">
              {videoData?.title && videoData?.title}
            </span>
            <Button
              onClick={!isComplate && handleVideoComplate}
              loading={loading || loadingBtt}
              disabled={isComplate}
              style={{ color: isComplate ? "#fff" : "#000" }}
            >
              {!isComplate ? "Darsni yakunlash" : "Dars yakunlangan"}
            </Button>
          </div>
          {matches && <SidebarCourse />}
        </>
      ),
    },
    {
      key: "2",
      label: <span className="">Izohlar</span>,
      children: <VideoComments />,
    },
  ];

  return (
    <div className="mx-auto w-full max-w-5xl">
      {loading ? (
        <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
          <div
            className="flex justify-center items-center"
            style={{
              border: "none",
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              width: "100%",
            }}
          >
            <div className="flex flex-col">
              <Spin size="large" />
              <span className="mt-4">Loading</span>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
          <iframe
            loading="lazy"
            title="tradehouse"
            src={`https://play.gumlet.io/embed/${videoData?.video}?preload=false&autoplay=false&loop=false&disable_player_controls=false`}
            style={{
              border: "none",
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              width: "100%",
            }}
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture; fullscreen;"
            frameBorder={0}
            allowFullScreen={true}
          ></iframe>
        </div>
      )}
      <Tabs
        className="custom-tabs"
        centered
        style={{ color: "#fff" }}
        defaultActiveKey="1"
        items={items}
        onChange={onChange}
        animated={false}
      />
    </div>
  );
};
