import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Progress, Result, Spin } from "antd";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { LessonContext } from "../../context/lesson-context";
import { UserContext } from "../../context/user-context";
import { FoundFavorite } from "../dashboard/assets/found";
import { HomeBg } from "./assets/home-bg";

export const Home = () => {
  const data = useContext(UserContext);
  const { viewLesson, completionPercentage } = useContext(LessonContext);

  return data.loading ? (
    <div className="text-center">
      <Spin className="!m-[auto] pt-12" size="large"></Spin>
    </div>
  ) : (
    <>
      <div className="w-full md:p-7 p-4 mb-2 custom-content-bg">
        <div className="flex items-center justify-between">
          <div className="flex flex-col space-y-2 gap-2 lg:w-1/2 w-full">
            <h1 className="text-[1.7rem] sm:text-[1.7rem] md:text-[1.5rem] xl:text-[2rem] font-semibold">
              Assalomu alaykum, <br />
              {data.data?.first_name
                ? data.data?.first_name
                : data.data?.first_name}
              , xush kelibsiz!
            </h1>
            <p className="opacity-70 !text-sm/[24px] !leading-5 max-w-[390px]">
              Kurs davomida muvaffaqiyatli o'qish va trading sohasida yorqin
              kelajak tilaymiz!
            </p>
          </div>
          <span className="hidden sm:flex w-[75%] ">
            <HomeBg />
          </span>
        </div>
      </div>
      <div className="w-full md:p-8 p-5 mb-2 custom-content-bg  ">
        <div className="flex justify-between">
          <h1
            className="mb-1 eading-7 py-4 font-semibold max-w-100"
            style={{ lineHeight: 0 }}
          >
            Yakunlangan darsliklar
          </h1>
        </div>
        <div className="mt-4 pt-8 pb-5 flex justify-center">
          <Progress
            format={(percent) => (
              <span
                style={{ color: "#EFF1F1", fontSize: 17 }}
              >{`${percent}%`}</span>
            )}
            percent={completionPercentage}
            status="active"
            trailColor="#5ab3ff36"
            strokeColor={{
              from: "#108ee9",
              to: "#87d068",
            }}
          />
        </div>
      </div>

      <div className="custom-content-bg w-full md:p-5 p-5 mb-2">
        {viewLesson ? (
          <>
            <h1 className="flex items-center justify-between px-3 mb-1  font-semibold  py-4 ps-[0px] md:ps-4 ">
              <span>Oxirgi tugallangan darslik :</span>
              <Link to={`/course/watch/${viewLesson?.video_id._id}`}>
                <ArrowRightOutlined />
              </Link>
            </h1>
            <div className="flex flex-col sm:flex-row items-center sm:justify-between gap-5 p-[0px] md:p-4 ">
              <div className="w-full sm:w-[35%]">
                <img
                  className="w-full rounded-xl"
                  src={`https://video.gumlet.io/${viewLesson?.video_id?.collection_id}/${viewLesson?.video_id?.video}/thumbnail-1-0.png`}
                  alt="tradehouse"
                />
              </div>
              <div className="w-full sm:w-[70%] flex flex-col gap-4 ">
                <p className="text-md text-white">
                  <span className="text-gray-300 pr-2">Mavzu:</span>
                  {viewLesson?.video_id.title}
                </p>
                <p className="text-md text-white">
                  <span className="text-gray-300 pr-2">Bo'lim:</span>
                  {viewLesson?.video_id.section_id?.title?.split(" ")[0]}
                </p>
              </div>
            </div>
          </>
        ) : (
          <Result
            icon={<FoundFavorite />}
            title={
              <>
                <p className="text-[#fff] text-lg">
                  Sizda oxirgi ko'rilgan darslik mavjud emas
                </p>
              </>
            }
            extra={
              <Link to="/course">
                <Button type="primary">Darsliklarga o'tish</Button>
              </Link>
            }
          />
        )}
      </div>
    </>
  );
};
