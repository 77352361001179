import React, { createContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { request } from "../api/api";

export const CourseContext = createContext(null);

export const CourseProvider = () => {
  const [courseSection, setSection] = useState([]);
  const [courseItems, setItem] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sectionLoading, setSectionLoading] = useState(true);

  const CourseSection = async () => {
    try {
      const response = await request.get("premium-section");
      if (response.status === 200) {
        setSection([...response.data.data]);
        setSectionLoading(false);
      }
    } catch (error) {
      console.error("Authentication check failed:", error);
    }
  };

  const CourseItem = async () => {
    try {
      const response = await request.get("premium/courseAll");
      if (response.status === 200) {
        setItem([...response.data.data]);
        setLoading(false);
      }
    } catch (error) {
      console.error("Authentication check failed:", error);
    }
  };
  useEffect(() => {
    CourseItem();
    CourseSection();
  }, []);

  return (
    <CourseContext.Provider
      value={{
        courseItems,
        loading,
        courseSection,
        sectionLoading,
      }}
    >
      <Outlet />
    </CourseContext.Provider>
  );
};
