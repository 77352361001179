import React from "react";
import { Outlet } from "react-router-dom";
import { SidebarCourse } from "../pages/course-video/components/sidebar/sidebar-video";

export const VideoLayout = () => {
  return (
    <>
      <div className="flex min-h-screen ">
        <div className="bg  border-r custom-scrollbar sticky inset-x-0 top-0 flex h-screen flex-col overflow-y-auto max-lg:hidden lg:w-[300px] z-50">
          <SidebarCourse />
        </div>
        <div className="main-bg flex min-h-screen pb-6 pt-28  sm:px-6 md:px-10 flex-1 flex-col px-4">
          <div className="custom-scrollbar mx-auto w-full max-w-6xl">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};
