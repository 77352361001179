import {
  DownOutlined,
  LeftOutlined,
  PlayCircleOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { Collapse } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useParams } from "react-router-dom";
import { CourseContext } from "../../../../context/course-context";

const { Panel } = Collapse;

export const SidebarCourse = () => {
  const item = useContext(CourseContext);
  const { id } = useParams();
  const [activeKey, setActiveKey] = useState([]);
  const matches = useMediaQuery({ query: "(max-width: 1023px)" });

  useEffect(() => {
    const sectionId = item.courseItems?.find((e) => e.id === id)?.section_id;
    if (sectionId) {
      setActiveKey([sectionId]);
    }
  }, [id, item.courseItems]);

  useEffect(() => {
    if (matches) {
      window.scrollTo(0, 0);
    }
  }, [id, matches]);

  const items = item.courseSection?.map((u, index) => ({
    key: u.id,
    header: (
      <span className="text-white text-[17px] custom-text-nowrap font-medium">
        {u?.title}
      </span>
    ),
    content: (
      <>
        {item.courseItems?.map(
          (e) =>
            u.id === e.section_id && (
              <Link key={e.id} to={`/course/watch/${e.id}`}>
                <p
                  className={`mt-3 px-3 py-[7px] custom-text-nowrap font-medium text-[16.3px] ${
                    e.id === id && "bg-[#7788996e]"
                  } hover:bg-[#7788996e] px-1 focus:outline-none focus:ring focus:ring-violet-300`}
                >
                  <PlayCircleOutlined style={{ paddingRight: 10 }} />
                  {e.title}
                </p>
              </Link>
            )
        )}
      </>
    ),
  }));

  return (
    <>
      <div className="flex flex-col">
        <h2 className="text-xl font-semibold text-white my-4 flex items-center">
          <Link to="/course">
            <LeftOutlined className="border p-1 mr-2 border-slate-700 text-lg hover:border-slate-500 cursor-pointer" />
            Darsliklar
          </Link>
        </h2>
        <div
          data-orientation="horizontal"
          role="none"
          className="shrink-0 bg-border h-[1px] w-full"
        ></div>
        <Collapse
          expandIconPosition="right"
          activeKey={activeKey}
          onChange={setActiveKey}
          className="custom-collapse"
          expandIcon={({ isActive }) =>
            !isActive ? (
              <DownOutlined style={{ color: "white" }} />
            ) : (
              <UpOutlined style={{ color: "white" }} />
            )
          }
          ghost
        >
          {items.map((item) => (
            <Panel
              key={item.key}
              header={item.header}
              style={{
                backgroundColor: "#3d3c582b",
              }}
            >
              <div className="text-white">{item.content}</div>
            </Panel>
          ))}
        </Collapse>
      </div>
    </>
  );
};
