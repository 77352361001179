import { Button } from "antd";
import React, { useContext } from "react";
import { Saved } from "./components/saved";
import { Link } from "react-router-dom";
import { UserContext } from "../../context/user-context";

export const Dashboard = () => {
  const data = useContext(UserContext);

  return (
    <>
      <div className="bg-gray-800 border border-gray-800 shadow-lg rounded-2xl p-4">
        <div className="flex sm:flex items-center gap-4 flex-col sm:flex-row">
          <div className="relative h-32 w-32 mb-4 sm:mb-0">
            <img
              src={data.data?.picture}
              alt="tradeHouse"
              className="w-32 h-32 object-cover rounded-2xl"
            />
          </div>
          <div className="flex-auto sm:ml-5">
            <div className="flex items-center justify-between sm:mt-2 flex-col sm:flex-row gap-4 sm:gap-0">
              <div>
                <div className="text-lg text-gray-200 font-bold leading-none max-w-[200px]">
                  {data.data?.first_name && data.data?.first_name + " "}
                  {data.data?.last_name && data.data?.last_name}
                </div>
                <div className="mt-4 text-gray-400">
                  <span className="mr-3">{data.data?.region}</span>
                  <span className="mr-3 border-r border-gray-600"></span>
                  <span>{data.data?.city}</span>
                </div>
              </div>
              <Link to="/dashboard/user/edit">
                <Button>Tahrirlash</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Saved />
    </>
  );
};
