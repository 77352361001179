import axios from "axios";
import { getCookie, setCookie, deleteCookie } from "./cookies";

export const request = axios.create({
  baseURL: "https://api.tradehouse.uz",
  withCredentials: true,
}); 

let isRefreshing = false;
let refreshSubscribers = [];

function subscribeTokenRefresh(cb) {
  refreshSubscribers.push(cb);
}

function onRefreshed(token) {
  refreshSubscribers.map((cb) => cb(token));
}

// Add a request interceptor to include the _client cookie
request.interceptors.request.use(
  (config) => {
    const clientToken = getCookie("__client");
    if (clientToken) {
      config.headers["Authorization"] = `Bearer ${clientToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

request.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      if (isRefreshing) {
        return new Promise((resolve) => {
          subscribeTokenRefresh((token) => {
            originalRequest.headers["Authorization"] = `Bearer ${token}`;
            resolve(request(originalRequest));
          });
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const response = await axios.get("https://api.tradehouse.uz/refresh", {
          withCredentials: true,
        });

        if (response.status === 200) {
          const newToken = response.data.token;
          setCookie("__client", newToken);
          request.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${newToken}`;
          originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
          onRefreshed(newToken);
          refreshSubscribers = [];
          return request(originalRequest);
        }
      } catch (refreshError) {
        refreshSubscribers = [];
        deleteCookie("__client");
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);

const maxRetries = 3;

// Add a request interceptor to attach the retry count
request.interceptors.request.use((config) => {
  config.retryCount = config.retryCount || 0;
  return config;
});

// Add a response interceptor to handle retries
request.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error.config;

    if (error.code === "ECONNABORTED" && config.retryCount < maxRetries) {
      config.retryCount += 1;
      console.log(`Retrying request... Attempt #${config.retryCount}`);
      return request(config);
    }

    return Promise.reject(error);
  }
);
