import { MenuOutlined } from "@ant-design/icons";
import { Drawer, Space } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Logo } from "../../../assets/logo";
import { Sidebar } from "../../sidebar/sidebar";
const ResponseMediaQuery = () => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Space onClick={showDrawer}>
        <MenuOutlined
          className="cursor-pointer"
          style={{ fontSize: "20px", color: "#fff" }}
        />
      </Space>
      <Drawer
        style={{
          width: "75%",
          backgroundColor: "#111827",
          color: "#fff !important",
        }}
        placement="left"
        closable={false}
        onClose={onClose}
        open={open}
      >
        <div className="pb-1 border-b">
          <Link className="flex items-center gap-1 pb-1" to="/">
            <Logo />
            <p
              style={{ letterSpacing: "3px" }}
              className="text-[23px] font-bold font-space-grotesk"
            >
              Premium
            </p>
          </Link>
        </div>
        <div className="pt-6">
          <Sidebar onClose={onClose} />
        </div>
      </Drawer>
    </>
  );
};
export default ResponseMediaQuery;
