import { Breadcrumb, Button } from "antd";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CourseContext } from "../../context/course-context";
import { CourseImg } from "./assets/course-img";
import { DotSvg } from "./assets/dot";
import { CommentsContent } from "./components/comments/commets";
import { CourseContent } from "./components/course-content/course-content";

export const Course = () => {
  const item = useContext(CourseContext);

  return (
    <div className="pb-9">
      <div className="mb-6">
        <Breadcrumb
          separator={
            <span className="text-white">
              <DotSvg />
            </span>
          }
          items={[
            {
              title: (
                <>
                  <Link to="/">
                    <span className="leading-[0] font-semibold text-lg">
                      Bosh sahifa
                    </span>
                  </Link>
                </>
              ),
            },
            {
              title: (
                <>
                  <span className="leading-[0] custom-viollet-cl font-semibold text-lg">
                    Darsliklar
                  </span>
                </>
              ),
            },
          ]}
        />
      </div>
      <div className="w-full md:p-8 p-5  mb-2 custom-content-bg">
        <div className="flex  justify-between">
          <div className="flex flex-col space-y-2 gap-3 lg:w-1/2 w-full">
            <h1 className="text-[2rem] xl:text-[2rem] md:text-[1.5rem] font-semibold">
              Darsliklar
            </h1>
            <p className="opacity-70 !text-sm/[24px] !leading-6 max-w-[390px]">
              Darsliklardan oqlona foydalanib eng samarali savdo strategiyalari
              bilan bozorni zabt eting.
            </p>
            <span className="pt-6">
              <Link to={`/course/watch/${item.courseItems[0]?.id}`}>
                <Button disabled={item.loading} size="large" type="primary">
                  Darsliklarga kirish
                </Button>
              </Link>
            </span>
          </div>
          <span className="hidden sm:flex">
            <CourseImg />
          </span>
        </div>
      </div>
      <CourseContent />
      <CommentsContent />
    </div>
  );
};
