import { Avatar, Spin, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Google } from "../../assets/google";
import { Latop } from "../../assets/latop";
import { PhoneLogo } from "../../assets/phone";
import { ArrowRightOutlined, UserOutlined } from "@ant-design/icons";
import { UserContext } from "../../../context/user-context";
import { request } from "../../../api/api";

export const ProfileInfo = ({ setEditProfile }) => {
  const data = useContext(UserContext);
  const { picture, first_name, last_name, email } = data.data;
  const [activityData, setActivityData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    request
      .get("latest-activity")
      .then((res) => {
        setActivityData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <div>
        <div style={{ borderColor: "#ededed" }} className="border-b mt-5">
          <Typography className="text-[17px] font-semibold">Profil</Typography>
        </div>
        <div
          className="flex items-center justify-between pt-2 ps-3 pr-3 pb-3 mt-2 mb-1 hover:bg-stone-200 rounded-2xl cursor-pointer"
          onClick={() => setEditProfile(true)}
        >
          <div className="flex items-center gap-3">
            <Avatar size={45} src={picture} icon={<UserOutlined />} />
            <Typography className="">
              {first_name && first_name} {last_name && last_name}
            </Typography>
          </div>
          <ArrowRightOutlined />
        </div>
      </div>
      <div style={{ borderColor: "#ededed" }} className="border-b mt-5">
        <Typography className="text-[17px] font-semibold	">
          Sizning hisobingiz
        </Typography>
      </div>
      <div className="flex items-center mt-1 cursor-default ">
        <span className="p-5">
          <Google />
        </span>
        <Typography>Google ({email})</Typography>
      </div>
      {/* <div style={{ borderColor: "#ededed" }} className="border-b mt-5">
        <Typography className="text-[17px] font-semibold">
          Ulangan qurilmalar
        </Typography>
      </div>
      <div className="mt-1 p-1 flex flex-col gap-3">
        {loading ? (
          <Spin className="!m-[auto] pt-4" size="large"></Spin>
        ) : (
          activityData?.map((e) =>
            e.isMobile ? (
              <div
                key={e.id}
                className="flex gap-5 items-center cursor-default"
              >
                <span style={{ width: "70px", height: "70px" }}>
                  <PhoneLogo />
                </span>
                <span>
                  <Typography className="font-semibold">
                    {e.country && e.country}/ {e.region && e.region}
                  </Typography>
                  <Typography className="custom-text-nowrap max-w-[250px] ">
                    IP :{e.ip && e.ip}
                  </Typography>
                </span>
              </div>
            ) : (
              <div
                key={e.id}
                className="flex gap-5 items-center cursor-default"
              >
                <span style={{ width: "70px", height: "70px" }}>
                  <Latop />
                </span>
                <span>
                  <Typography className="font-semibold">
                    {e.country && e.country}/ {e.region && e.region}
                  </Typography>
                  <Typography className="custom-text-nowrap max-w-[250px] ">
                    IP :{e.ip && e.ip}
                  </Typography>
                </span>
              </div>
            )
          )
        )} */}
      {/* </div> */}
    </>
  );
};
