import React, { useState } from "react";
import { Button, Input, Modal, Rate, message } from "antd";
import { CloseOutlined, StarOutlined } from "@ant-design/icons";
import "./style/assessment.css";
import { request } from "../../../api/api";

export const Assessment = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rating, setRating] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [data, setData] = useState({ rate: 0, comment: "" });
  const [loadingButton, setLoadingButton] = useState(false);

  const showModal = () => setIsModalOpen(true);
  const handleCancel = () => {
    setDisabled(false);
    setIsModalOpen(false);
    setRating(0);
    setData({ rate: 0, comment: "" });
  };
  const handleRateChange = (value) => {
    setDisabled(true);
    setRating(value);
    setData({ ...data, rate: value });
  };

  const handleSubmit = (e) => {
    setLoadingButton(true);
    e.preventDefault();
    request
      .post("course-comments", { ...data })
      .then(() => {
        handleCancel();
        setLoadingButton(false);
      })
      .catch((err) => {
        if (err.response?.status === 403)
          return message.error("Xatolik sizning sharhingiz allaqachon mavjud");
      });
  };

  return (
    <>
      <span
        onClick={showModal}
        className="hidden sm:flex cursor-pointer items-center gap-1 opacity-60 transition-all duration-200 hover:opacity-100"
      >
        <StarOutlined /> Baholash
      </span>
      <Modal
        open={isModalOpen}
        centered
        onCancel={handleCancel}
        footer={null}
        closeIcon={
          <CloseOutlined style={{ color: "#fff" }} onClick={handleCancel} />
        }
        className="modal-custom"
      >
        <form onSubmit={handleSubmit}>
          <div className="text-white text-center font-medium text-[16.3px]">
            <h1>
              {!disabled
                ? "Ushbu kursni qanday baholaysiz"
                : "Nega bunday baho berdingiz?"}
            </h1>
            <Rate
              allowHalf
              value={rating}
              className="mt-5"
              onChange={handleRateChange}
              disabled={disabled}
            />
          </div>
          {disabled && (
            <div className="mt-5">
              <span className="text-gray-400 !text-left block">
                Kurs haqida qisqacha fikringiz
              </span>
              <Input.TextArea
                onChange={(e) => setData({ ...data, comment: e.target.value })}
                maxLength={450}
                className="!min-h-[120px] mt-1 border-none text-[#fff] !text-sm !bg-gray-700 !resize-none"
              />
              <div className="mt-3 text-right">
                <Button loading={loadingButton} htmlType="submit">
                  Yuborish
                </Button>
              </div>
            </div>
          )}
        </form>
      </Modal>
    </>
  );
};
