import Cookies from "js-cookie";

export function getCookie(name) {
  return Cookies.get(name, { domain: ".tradehouse.uz" });
}

export function setCookie(name, value, days) {
  Cookies.set(name, value, { expires: days, domain: ".tradehouse.uz" });
}

export function deleteCookie(name) {
  Cookies.remove(name, { domain: ".tradehouse.uz" });
  Cookies.remove(name, { domain: "tradehouse.uz" });
}
