import React from "react";
import { Outlet } from "react-router-dom";
import { Sidebar } from "../sidebar/sidebar";

export const SidebarLayout = () => {
  return (
    <div className="flex min-h-screen">
      <div className="bg custom-scrollbar sticky inset-x-0 top-0 flex h-screen flex-col overflow-y-auto p-6 pt-24 max-lg:hidden lg:w-[300px]">
        <Sidebar />
      </div>
      <div className="main-bg flex min-h-screen pb-6 pt-32 max-md:pb-14 sm:px-6 md:px-10 flex-1 flex-col px-4">
        <div className="custom-scrollbar mx-auto w-full max-w-6xl">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
