import {
  DeleteOutlined,
  EllipsisOutlined,
  StarFilled,
  UserOutlined,
  VerticalAlignTopOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Dropdown, Menu, Progress, Rate, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { request } from "../../../../api/api";
import { AuthContext } from "../../../../context/auth-content";
import { CourseContext } from "../../../../context/course-context";
import { Link } from "react-router-dom";

export const CommentsContent = () => {
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const { userRole } = useContext(AuthContext);
  const [commentsALL, setCommentsALL] = useState([]);
  const item = useContext(CourseContext);

  useEffect(() => {
    loadComments(0, 6);
    request.get(`course-comments/all`).then((res) => {
      setCommentsALL([...res.data]);
    });
  }, []);

  const loadComments = (offset, limit) => {
    setLoading(true);
    request
      .get(`course-comments?offset=${offset}&limit=${limit}`)
      .then((res) => {
        setComments((prevComments) => [...prevComments, ...res.data]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Failed to load comments:", error);
        message.error("Sharhlarni yuklashda xatolik yuz berdi");
      });
  };

  const showMoreComments = () => {
    loadComments(comments.length, 8);
  };

  const totalRatings = commentsALL.length;
  const averageRating =
    commentsALL.reduce((sum, item) => sum + item.rate, 0) / totalRatings;

  const ratingCounts = Array(5).fill(0);
  commentsALL.forEach((item) => {
    const roundedRate = Math.round(item.rate);
    ratingCounts[5 - roundedRate]++;
  });

  const handleMenuClick = (u, e) => {
    if (u.key === "delete") {
      request.delete(`course-comments/${e}`).then((res) => {
        setComments((prevComments) =>
          prevComments.filter((comment) => comment.id !== e)
        );
        message.success("Muvaffaqiyatli o'chirildi");
      });
    }
    if (u.key === "global") {
      request.patch(`course-comments/${e}`).then((res) => {
        message.success("Asosiy sharhlarga qo'shildi!");
      });
    }
  };

  return (
    <>
      <div className="mt-[50px] flex align-center gap-2">
        <StarFilled style={{ fontSize: 20, color: "yellow" }} />
        <span className="tracking-wide text-lg sm:text-xl">
          Baholash va sharhlar
        </span>
      </div>
      <div className="mt-[45px] grid gap-x-6 flex-grow grid-cols-[max-content_auto]">
        <div className="text-center flex flex-col">
          <span className="text-[45px]">
            {!isNaN(averageRating) ? averageRating.toFixed(1) : 0}
          </span>
          <Rate
            className="md:text-medium text-[16px]"
            disabled
            allowHalf
            value={averageRating}
          />
        </div>
        <div className="w-full">
          {ratingCounts.map((count, index) => (
            <span key={index} className="flex gap-2 text-sm">
              {5 - index}
              <Progress
                trailColor="#3432648c"
                strokeColor={"yellow"}
                percent={(count / totalRatings) * 100}
                showInfo={false}
              />
            </span>
          ))}
        </div>
      </div>
      <div className="mt-[70px] grid grid-cols-1 sm:grid-cols-2 gap-[70px]">
        {comments?.map((e, index) => (
          <div key={index} className="flex flex-col">
            <div className="flex justify-between">
              <div className="flex cursor-pointer items-center gap-3">
                <Avatar
                  size={45}
                  src={e.user?.picture}
                  icon={<UserOutlined />}
                />
                <span className="flex flex-col">
                  <span className="custom-text-nowrap text-md">
                    {e.user?.first_name} {e.user?.last_name}
                  </span>
                  <span>
                    <Rate
                      style={{ fontSize: 15 }}
                      disabled
                      defaultValue={e.rate}
                      allowHalf
                    />
                  </span>
                </span>
              </div>
              {userRole === "admin" && (
                <>
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item
                          key="global"
                          className="!text-[#1677ff]"
                          onClick={(u) => handleMenuClick(u, e.id)}
                        >
                          <div className="flex justify-center gap-3">
                            <span>Global</span>
                            <VerticalAlignTopOutlined
                              style={{ color: "#1677ff" }}
                            />
                          </div>
                        </Menu.Item>
                        <Menu.Item
                          key="delete"
                          className="!text-[#fe0000]"
                          onClick={(u) => handleMenuClick(u, e.id)}
                        >
                          <div className="flex justify-center gap-2">
                            <span>O'chirish</span>
                            <DeleteOutlined style={{ color: "#fe0000" }} />
                          </div>
                        </Menu.Item>
                      </Menu>
                    }
                    placement="top"
                    trigger={["click"]}
                    arrow
                  >
                    <EllipsisOutlined
                      style={{ color: "#fff", cursor: "pointer" }}
                    />
                  </Dropdown>
                </>
              )}
            </div>
            <span className="tracking-wide text-[15px] pt-3 max-w-[350px]">
              {e.comment}
            </span>
          </div>
        ))}
      </div>
      {commentsALL?.length >= 7 && (
        <Button
          type="primary"
          className="mt-9 mb-7"
          loading={loading}
          onClick={showMoreComments}
        >
          Yana sharh ko'rsatish
        </Button>
      )}
      <div className="bg fixed bottom-0 items-center inset-x-0 flex justify-between px-3 py-4 border-t lg:hidden">
        <div>
          <div className="text-xl font-medium">Darsliklar</div>
          <div>
            <span className="text-medium">
              {!isNaN(averageRating) ? averageRating.toFixed(1) : 0}
            </span>
            <Rate
              className="ml-3 md:text-medium text-[16px]"
              disabled
              allowHalf
              value={averageRating}
            />
          </div>
        </div>
        <Link to={`/course/watch/${item.courseItems[0]?.id}`}>
          <Button
            loading={item.loading}
            type="primary"
            size="large"
            className="px-12"
          >
            Kirish
          </Button>
        </Link>
      </div>
    </>
  );
};
