import { Button, Result } from "antd";
import { Link, Route, Routes } from "react-router-dom";
import "./App.css";
import {
  AuthProvider,
  Course,
  CourseVideo,
  Dashboard,
  EditProfile,
  Home,
  MainLayout,
  RouterAll,
  SidebarLayout,
  UserProvider,
  VideoLayout,
} from "./components";
import { CourseProvider } from "./components/context/course-context";
import { LessonViewProvider } from "./components/context/lesson-context";
import { UserCommentProvider } from "./components/context/user-comment.contex";
import { AllUser } from "./components/pages/all-user/all-user";

function App() {
  return (
    <Routes>
      <Route element={<AuthProvider />}>
        <Route element={<UserProvider />}>
          <Route element={<CourseProvider />}>
            <Route element={<LessonViewProvider />}>
              <Route element={<UserCommentProvider />}>
                <Route
                  path="/"
                  element={
                    <AuthProvider>
                      <RouterAll />
                    </AuthProvider>
                  }
                >
                  <Route path="/" element={<MainLayout />}>
                    <Route element={<SidebarLayout />}>
                      <Route path="/" element={<Home />} />
                      <Route path="/course" element={<Course />} />
                      <Route path="/users" element={<AllUser />} />
                      <Route path="/users/:id" element={<AllUser />} />
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route
                        path="/dashboard/user/edit"
                        element={<EditProfile />}
                      />
                    </Route>
                    <Route path="/course/watch/:id" element={<VideoLayout />}>
                      <Route index element={<CourseVideo />} />
                    </Route>
                  </Route>
                </Route>
                <Route
                  path="*"
                  element={
                    <Result
                      status="404"
                      title={<span className="text-[#fff]">404</span>}
                      subTitle={
                        <span className="text-[#fff]">Sahifa mavjud emas</span>
                      }
                      extra={
                        <Link to="/">
                          <Button type="primary">
                            Asosiy sahifaga qaytish
                          </Button>
                        </Link>
                      }
                    />
                  }
                />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
