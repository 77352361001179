import { Button, Form, Input, Space, message } from "antd";
import React, { useContext, useState } from "react";
import { request } from "../../../api/api";
import { UserContext } from "../../../context/user-context";

export const EditProfileNav = () => {
  const { data, UserData } = useContext(UserContext);

  const [loadingButton, setLoadingButton] = useState(false);

  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    setLoadingButton(true);
    request
      .patch("user/update/me", { ...values })
      .then(() => {
        UserData();
        message.success("Yangilandi");
        setLoadingButton(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Form
        form={form}
        name="edit-profile"
        layout="vertical"
        autoComplete="off"
        className="mt-3"
        initialValues={{
          first_name: data?.first_name,
          last_name: data?.last_name,
        }}
        onFinish={handleSubmit}
      >
        <Form.Item
          name="first_name"
          label="Ism"
          rules={[{ required: true, message: "Iltimos, ismingizni kiriting!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="last_name"
          label="Familiya"
          rules={[
            { required: true, message: "Iltimos, familiyangizni kiriting!" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item className="text-right">
          <Space>
            <Button loading={loadingButton} htmlType="submit" type="primary">
              Saqlash
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};
