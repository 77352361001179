import { FileImageOutlined } from "@ant-design/icons";
import { message } from "antd";
import React, { useContext, useRef } from "react";
import { useCookies } from "react-cookie";
import { useMediaQuery } from "react-responsive";
import { request } from "../../../api/api";
import { UserContext } from "../../../context/user-context";
import { UserCommentContext } from "../../../context/user-comment.contex";

export const ImageUpload = () => {
  const user = useContext(UserContext);
  const { UserComments } = useContext(UserCommentContext);
  const fileInputRef = useRef(null);

  const handleUpload = async (file) => {
    try {
      const formData = new FormData();
      formData.append("image", file);

      const response = await request.post(`user/image/update/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 201) {
        UserComments();
        user.UserData();
        message.success(`Rasm yangilandi`);
      } else {
        message.error("Rasmni yuklashda xatolik yuz berdi.");
      }
    } catch (error) {
      console.log(error);
      message.error("Rasmni yuklashda xatolik yuz berdi.");
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error(
          "Faqat JPG yoki PNG formatidagi rasmlarni yuklashingiz mumkin!"
        );
        return;
      }
      handleUpload(file);
    }
  };

  return (
    <>
      <span
        style={{
          cursor: "pointer",
          color: "#4c3387",
          fontWeight: 600,
        }}
        onClick={() => fileInputRef.current.click()}
      >
        Rasmni yangilash
      </span>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </>
  );
};
