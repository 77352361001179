import {
  DeleteOutlined,
  MoreOutlined,
  SendOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Dropdown, Flex, Menu, Spin } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { request } from "../../../../api/api";
import { AuthContext } from "../../../../context/auth-content";

export const VideoComments = () => {
  const [commentsData, setCommentData] = useState([]);
  const user = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [commentInput, setCommentInput] = useState("");
  const { id } = useParams();
  const commentsRef = useRef(null);
  const endOfCommentsRef = useRef(null);

  useEffect(() => {
    setLoading(true);
    request.get(`video-comments/${id}`).then((res) => {
      setCommentData([...res.data]);
      setLoading(false);
      if (endOfCommentsRef.current) {
        endOfCommentsRef.current.scrollIntoView({ behavior: "smooth" });
      }
    });
    setCommentInput("");
    setLoadingSubmit(false);
  }, [id]);

  const handleMenuClick = (u, e) => {
    if (u.key === "delete") {
      request.delete(`video-comments/${id}/${e}`).then((res) => {
        setCommentData([...res.data]);
      });
    }
  };

  const handleSubmit = (e) => {
    setLoadingSubmit(true);
    e.preventDefault();
    if (commentInput?.length >= 3) {
      request
        .post(
          `video-comments/${id}`,
          { comment: commentInput },
          { headers: { "Content-Type": "application/json" } }
        )
        .then((res) => {
          setCommentData([...commentsData, { ...res.data }]);
          setCommentInput("");
          setLoadingSubmit(false);
          if (endOfCommentsRef.current) {
            endOfCommentsRef.current.scrollIntoView({ behavior: "smooth" });
          }
        });
    }
  };

  return (
    <>
      <div className="w-full p-4 mb-2 custom-content-bg">
        <span className="text-lg text-medium">
          Izohlar soni {commentsData?.length} ta
        </span>
        {loading ? (
          <Flex justify="center" className="py-8" gap="small">
            <Spin tip="Loading" size="large"></Spin>
          </Flex>
        ) : (
          <>
            <div
              ref={commentsRef}
              id="comments"
              className="flex flex-col max-h-screen overflow-y-auto pt-12 pb-[68px]"
            >
              {commentsData?.length === 0 ? (
                <div className="text-center py-8">
                  <span>Hozirda hech qanday izohlar yo'q</span>
                </div>
              ) : (
                commentsData.map((e) => (
                  <div key={e?._id} className="flex mb-4">
                    <div className="w-[45px] h-[45px]">
                      <Avatar
                        size={45}
                        src={e?.user?.picture}
                        icon={<UserOutlined />}
                      />
                    </div>
                    <div className="ml-2 py-3 px-4 bg-[#2a3040] rounded-br-3xl rounded-tr-3xl rounded-tl-xl text-white">
                      <div className="flex justify-between gap-3">
                        <p
                          className="font-medium custom-text-nowrap max-w-[200px]"
                          style={{ color: "#eff4ffad" }}
                        >
                          {e.user?.first_name} {e.user?.last_name}
                        </p>
                        {user.userID === e.user?._id && (
                          <Dropdown
                            overlay={
                              <Menu onClick={(u) => handleMenuClick(u, e.id)}>
                                <Menu.Item
                                  key="delete"
                                  className="!text-[#fe0000] flex gap-3"
                                >
                                  <span>O'chirish</span>
                                  <DeleteOutlined
                                    style={{ color: "#fe0000" }}
                                  />
                                </Menu.Item>
                              </Menu>
                            }
                            placement="top"
                            trigger={["click"]}
                            arrow
                          >
                            <MoreOutlined
                              style={{ color: "#fff", cursor: "pointer" }}
                            />
                          </Dropdown>
                        )}
                      </div>
                      <p>{e.comment}</p>
                    </div>
                  </div>
                ))
              )}
              <div ref={endOfCommentsRef} />
            </div>
            <div className="border-gray-300 p-4 absolute bottom-0 w-full sm:w-3/4 left-[50%] translate-x-[-50%]">
              <form onSubmit={handleSubmit}>
                <div className="flex items-center">
                  <input
                    type="text"
                    onChange={(e) => setCommentInput(e.target.value)}
                    placeholder="@Type a message..."
                    value={commentInput}
                    className="w-full p-2 text-[#000] rounded-md border border-gray-400 focus:outline-none focus:border-blue-500"
                  />
                  <Button
                    loading={loadingSubmit}
                    disabled={commentInput?.length <= 2}
                    htmlType="submit"
                    className="bg-indigo-500 h-[auto] text-white !px-4 !pb-2 rounded-md ml-2"
                    type="primary"
                  >
                    {!loadingSubmit && (
                      <SendOutlined style={{ color: "#fff" }} />
                    )}
                  </Button>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </>
  );
};
